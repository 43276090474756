import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../img/main_logo.png";
import { useContext } from "react";
import { TbLogout } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import axios from "axios";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currPath = location.pathname;
  const { show, setShow, logindetails } = useContext(Context);
  console.log(logindetails, "dadf");
  const handleClose = () => setShow(false);

  const logout = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/logout",
        {},
        {
          headers: {
            user_id: logindetails.user_id,
            api_key: logindetails.user_api_key,
          },
        }
      );
      console.log(response.data.result);
      if (response.data.result === true) {
        navigate("/");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const daNavigate = () => {
    navigate("/DashBoard");
    setShow(!show);
  };
  const daAddIncome = () => {
    navigate("/add_income");
    setShow(!show);
  };
  const daAddProduct = () => {
    navigate("/add_product");
    setShow(!show);
  };
  const daProductList = () => {
    navigate("/product_list");
    setShow(!show);
  };
  const daWithdraw = () => {
    navigate("/Withdraw");
    setShow(!show);
  };

  const ActivationWallet = () => {
    navigate("/Activation");
    setShow(!show);
  };

  const Department = () => {
    navigate("/Department");
    setShow(!show);
  };



  const userSessionPath = localStorage.getItem("user_session");

  return (
    <>
      <div className="cx-sidebar-main " style={{ zIndex: 1 }}>
        <div className="sidebar d-flex  flex-column justify-content-between sidecontent">
          <div>
            <div className="  p-4 logomaindiv ">
              <a href="d-flex align-item-center ">
                <i className="fs-5    ">
                  <img
                    src={Logo}
                    alt="logo"
                    className="blacklogoimage"
                    style={{ width: 150, height: 100 }}
                  />
                </i>
              </a>
            </div>
            <div className="menu-wrapper">
              {console.log(
                userSessionPath,
                "{console.log(userSessionPath)}{console.log(userSessionPath)}"
              )}

              {userSessionPath === "null" ? (
                <div className="menus">
                  <div
                    className={
                      currPath === "/DashBoard" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/DashBoard")}
                  >
                    Dashboard
                  </div>

                  <div
                    className={
                      currPath === "/add_income" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/add_income")}
                  >
                    Add Income
                  </div>

                  <div
                    className={
                      currPath === "/Withdraw" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/Withdraw")}
                  >
                    Withdraw request
                  </div>

                  <div
                    className={
                      currPath === "/Activation"
                        ? "menu active-menu"
                        : "menu"
                    }
                    onClick={() => navigate("/Activation")}
                  >
                    Activation 
                  </div>

                  <div
                    className={
                      currPath === "/Department" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/Department")}
                  >
                    Department
                  </div>

                  <div
                    className={
                      currPath === "/edit_member" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/add_product")}
                  >
                    Add Product
                  </div>

                  <div
                    className={
                      currPath === "/product_list" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/product_list")}
                  >
                    Product List
                  </div>

                  <div
                    className="logout menu"
                    style={{ color: "#1154DD" }}
                    onClick={logout}
                  >
                    <TbLogout className="TbLogout" color="#101376" style={{}} />{" "}
                    LOG OUT
                  </div>
                </div>
              ) : (
                <div className="menus">
                  {userSessionPath == "/DashBoard" && (
                    <div
                      className={
                        currPath === "/DashBoard" ? "menu active-menu" : "menu"
                      }
                      onClick={() => navigate("/DashBoard")}
                    >
                      Dashboard
                    </div>
                  )}

                  {userSessionPath == "/add_income" && (
                    <div
                      className={
                        currPath === "/add_income" ? "menu active-menu" : "menu"
                      }
                      onClick={() => navigate("/add_income")}
                    >
                      Add Income
                    </div>
                  )}

                  {userSessionPath == "/Withdraw" && (
                    <div
                      className={
                        currPath === "/Withdraw" ? "menu active-menu" : "menu"
                      }
                      onClick={() => navigate("/Withdraw")}
                    >
                      Withdraw request
                    </div>
                  )}
                  {userSessionPath == "/Activation" && (
                    <div
                      className={
                        currPath === "/Activation"
                          ? "menu active-menu"
                          : "menu"
                      }
                      onClick={() => navigate("/Activation")}
                    >
                      Activation 
                    </div>
                  )}
                  {userSessionPath == "/Department" && (
                    <div
                      className={
                        currPath === "/Department" ? "menu active-menu" : "menu"
                      }
                      onClick={() => navigate("/Department")}
                    >
                      Department
                    </div>
                  )}

                  {userSessionPath == "/add_product" && (
                    <div
                      className={
                        currPath === "/edit_member"
                          ? "menu active-menu"
                          : "menu"
                      }
                      onClick={() => navigate("/add_product")}
                    >
                      Add Product
                    </div>
                  )}
                  {userSessionPath == "/product_list" && (
                    <div
                      className={
                        currPath === "/product_list"
                          ? "menu active-menu"
                          : "menu"
                      }
                      onClick={() => navigate("/product_list")}
                    >
                      Product List
                    </div>
                  )}

                  <div
                    className="logout menu"
                    style={{ color: "#1154DD" }}
                    onClick={logout}
                  >
                    <TbLogout className="TbLogout" color="#101376" style={{}} />{" "}
                    LOG OUT
                  </div>
                </div>
              )}
              {/* <div className="menus">
                {userSessionPath == "/DashBoard" && (
                  <div
                    className={
                      currPath === "/DashBoard" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/DashBoard")}
                  >
                    Dashboard
                  </div>
                )}

                {(userSessionPath === null ||
                  userSessionPath == "/add_income") && (
                  <div
                    className={
                      currPath === "/add_income" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/add_income")}
                  >
                    Add Income
                  </div>
                )}

                {(userSessionPath === null ||
                  userSessionPath == "/Withdraw") && (
                  <div
                    className={
                      currPath === "/Withdraw" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/Withdraw")}
                  >
                    Withdraw request
                  </div>
                )}

                {(userSessionPath === null ||
                  userSessionPath == "/Department") && (
                  <div
                    className={
                      currPath === "/Department" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/Department")}
                  >
                    Department
                  </div>
                )}

                {(userSessionPath === null ||
                  userSessionPath == "/add_product") && (
                  <div
                    className={
                      currPath === "/edit_member" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/add_product")}
                  >
                    Add Product
                  </div>
                )}
                {(userSessionPath === null ||
                  userSessionPath == "/product_list") && (
                  <div
                    className={
                      currPath === "/product_list" ? "menu active-menu" : "menu"
                    }
                    onClick={() => navigate("/product_list")}
                  >
                    Product List
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-sidebar">
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Body>
            <div className="sidebar d-flex  flex-column justify-content-between sidecontent">
              <div>
                <div className="  p-4 logomaindiv ">
                  <a href="d-flex align-item-center ">
                    <i className="fs-5    ">
                      <img src={Logo} alt="logo" className="blacklogoimage" />
                    </i>
                  </a>
                </div>
                <div className="menu-wrapper">
                  {userSessionPath == "null" ? (
                    <div className="menus">
                      <div
                        className={
                          currPath === "/DashBoard"
                            ? "menu active-menu"
                            : "menu"
                        }
                        onClick={daNavigate}
                      >
                        Dashboard
                      </div>
                      <div
                        className={
                          currPath === "/add_income"
                            ? "menu active-menu"
                            : "menu"
                        }
                        onClick={daAddIncome}
                      >
                        Add Income
                      </div>

                      <div
                        className={
                          currPath === "/Withdraw" ? "menu active-menu" : "menu"
                        }
                        onClick={daWithdraw}
                      >
                        Withdraw request
                      </div>
                      <div
                        className={
                          currPath === "/Activation"
                            ? "menu active-menu"
                            : "menu"
                        }
                        onClick={ActivationWallet}

                      >
                        Activation
                      </div>
                      <div
                        className={
                          currPath === "/Department"
                            ? "menu active-menu"
                            : "menu"
                        }
                        onClick={Department}

                        

                      >
                        Department
                      </div>
                      <div
                        className={
                          currPath === "/edit_member"
                            ? "menu active-menu"
                            : "menu"
                        }
                        onClick={daAddProduct}
                      >
                        Add Product
                      </div>
                      <div
                        className={
                          currPath === "/product_list"
                            ? "menu active-menu"
                            : "menu"
                        }
                        onClick={daProductList}
                      >
                        Product List
                      </div>
                      <div
                        className="logout menu"
                        style={{ color: "#1154DD" }}
                        onClick={logout}
                      >
                        <TbLogout
                          className="TbLogout"
                          color="#101376"
                          style={{}}
                        />{" "}
                        LOG OUT
                      </div>
                    </div>
                  ) : (
                    <div className="menus">
                      {userSessionPath == "/DashBoard" && (
                        <div
                          className={
                            currPath === "/DashBoard"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={daNavigate}
                        >
                          Dashboard
                        </div>
                      )}

                      {userSessionPath == "/add_income" && (
                        <div
                          className={
                            currPath === "/add_income"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={daAddIncome}
                        >
                          Add Income
                        </div>
                      )}

                      {userSessionPath == "/Withdraw" && (
                        <div
                          className={
                            currPath === "/Withdraw"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={daWithdraw}
                        >
                          Withdraw request
                        </div>
                      )}


{userSessionPath == "/Activation" && (
                        <div
                          className={
                            currPath === "/Activation"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={ActivationWallet}
                        >
                          Activation 
                        </div>
                      )}


                      {userSessionPath == "/Department" && (
                        <div
                          className={
                            currPath === "/Department"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={Department}
                        >
                          Department
                        </div>
                      )}
                      {userSessionPath == "/edit_member" && (
                        <div
                          className={
                            currPath === "/edit_member"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={daAddProduct}
                        >
                          Add Product
                        </div>
                      )}

                      {userSessionPath == "/product_list" && (
                        <div
                          className={
                            currPath === "/product_list"
                              ? "menu active-menu"
                              : "menu"
                          }
                          onClick={daProductList}
                        >
                          Product List
                        </div>
                      )}

                      <div
                        className="logout menu"
                        style={{ color: "#1154DD" }}
                        onClick={logout}
                      >
                        <TbLogout
                          className="TbLogout"
                          color="#101376"
                          style={{}}
                        />{" "}
                        LOG OUT
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
