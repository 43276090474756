import React, { useEffect, useContext, useState } from "react";
import Layout from "../Component/Layout";
import { Context } from "../context/Context";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import { ThreeDots } from 'react-loader-spinner';


const Withdrawal = () => {
  const { logindetails } = useContext(Context);
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  // const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    if (logindetails && logindetails.user_id && logindetails.user_api_key) {
      localStorage.setItem("user_id", logindetails.user_id);
      localStorage.setItem("user_api", logindetails.user_api_key);
    }
  }, [logindetails]);

  const searchedMembers = list.filter((member) =>
    member.u_referal_id.toLowerCase().includes(query.toLowerCase())
  );

  const withdrawList = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const user_api = localStorage.getItem("user_api");

      if (!user_id || !user_api) {
        setError("User ID or API key is missing.");
        console.error("User ID or API key is missing.");
        return;
      }


      console.log("Making API request with user_id:", user_id, "and api_key:", user_api);

      const response = await axios.get(
        "https://lunarsenterprises.com:3004/mlm/admin/notification",
        {
          headers: {
            user_id: user_id,
            api_key: user_api,
          },
        }
      );

      console.log("Full API response:", response, user_api, user_id,);

      if (response.data && response.data.result === false) {
        setError(response.data.message);
        console.error("API response error:", response.data.message);
        return;
      }

      if (response.data && Array.isArray(response.data.data)) {
        setList(response.data.data);
      } else {
        console.error("Response data is not an array:", response.data.data);
      }
    } catch (error) {
      console.log("Error fetching withdrawal list:", error);
    }
  };

  const withdrawApprovel = async (id) => {
    setLoading(true);
    console.log(id);
    try {

      const user_id = localStorage.getItem("user_id");
      const user_api = localStorage.getItem("user_api");

      if (!user_id || !user_api) {
        setError("User ID or API key is missing.");
        console.error("User ID or API key is missing.");
        return;
      }
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/income/withdrawal/approval",
        {
          n_id: id
        },
        {
          headers: {
            user_id: user_id,
            user_api: user_api
          }
        }
      )
      console.log(response);
      withdrawList();
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false); // Hide spinner when request is complete
    }
  }

  useEffect(() => {
    withdrawList();
  }, []);

  return (
    <Layout>
      <div className="text-center">
        <h2>Withdraw Request</h2>
      </div>
      <div className="search-box">
        <FaSearch id="search-icon" />
        <input
          id="search"
          type="text"
          placeholder="search member referral id"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
      </div>
      <div className="mt-5">
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "800px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Name</th>
                <th scope="col">Income Type</th>
                <th scope="col">Available Amount</th>
                <th scope="col">Amount</th>
                <th scope="col">Withdraw Date</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Joining Date</th>
                <th scope="col">Referral No</th>
                <th scope="col">Bank Name</th>
                <th scope="col">Account Number</th>
                <th scope="col">IFSC Code</th>
                <th scope="col">Status</th>
                <th scope="col">Change Status</th>
              </tr>
            </thead>
            <tbody>
              {searchedMembers.length > 0 ? (
                searchedMembers.map((item, index) => (
                  <tr className="line1px" key={index}>
                    <th scope="row">{searchedMembers.length - index}</th>
                    <td>{item.u_first_name}</td>
                    <td>{item.n_income}</td>
                    <td>{item.u_income}</td>
                    <td>{item.n_amount}</td>
                    <td>{moment(item.n_date).format("YYYY-MM-DD")}</td>
                    <td>{item.u_mobile}</td>
                    <td>{item.n_dates}</td>
                    <td>{item.u_referal_id}</td>
                    <td>{item.b_name}</td>
                    <td>{item.acc_number}</td>
                    <td>{item.ifsc_code}</td>
                    <td>{item.n_status}</td>
                    <td>
                      <button
                      key={index}
                        onClick={() => withdrawApprovel(item.n_id)}
                        disabled={loading || item.n_status === "approved"}  // Disable if loading or status is approved
                        className={item.n_status === "approved" ? "green" : "normal"}
                      >
                        {loading ? (
                          <ThreeDots color="#00BFFF" height={20} width={50} />
                        ) : (
                          item.n_status === "approved" ? 'Approved' : 'Approve'
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Withdrawal;
