import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import Layout from "../../Component/Layout";
import moment from "moment";
import { FaSearch } from "react-icons/fa";

const IncomeWithdrawwallet = () => {
  const storedUserId = localStorage.getItem("user_id");
  const [query, setQuery] = useState("");

  const [userhistory, setUserhistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userlogin, setUserlogin] = useState(null);

  useEffect(() => {
    fetchUserHistory();
  }, []);

  const fetchUserHistory = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/upi/transaction/list"
      );
      setUserhistory(response.data.withdraw_list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching data. Please try again.");
      console.error("Error fetching user history:", error);
    }
  };

  const Activation = async (n_id, newStatus) => {
    setUserlogin(n_id);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/upi/transaction/approve",
        { n_id: n_id, status: newStatus }
      );

      if (response.data.result === true) {
        alert("Approval Successful");
        fetchUserHistory();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error approving user:", error);
    } finally {
      setUserlogin(null);
    }
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(`https://lunarsenterprises.com:3004/${imagePath}`);
    setShowModal(true);
  };

  const filteredUserHistory = userhistory.filter((details) =>
    details.u_referal_id.toLowerCase().includes(query)
  );

  return (
    <div className="container-fluid myprofile py-5">
      {loading ? (
        <div className="">
          <ThreeDots color="#00BFFF" height={20} width={50} />
        </div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <div className="search-box mb-3">
            <FaSearch id="search-icon" />
            <input
              id="search"
              type="text"
              placeholder="Search by referral ID"
              value={query}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
            />
          </div>
          <div>
            <div className="addwallet"></div>
          </div>

          <div className="table-responsive mt-4">
            <table className="table table-light table-hover shadow">
              <thead>
                <tr>
                  <th scope="col">Sl no</th>
                  <th scope="col">Income Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Referral Id</th>
                  <th scope="col">Request Date</th>
                  <th scope="col">Activation</th>
                </tr>
              </thead>
              <tbody>
                {filteredUserHistory?.length > 0 ? (
                  filteredUserHistory.map((details, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{details.n_income}</td>
                      <td>{details.n_amount}</td>
                      <td>{details.u_referal_id}</td>
                      <td>{moment(details.n_date).format("DD-MM-YYYY")}</td>

                      <td className="text-capitalize">
                        {details.n_status === "pending" ? (
                          <span>
                            <Button
                              onClick={() =>
                                Activation(details.n_id, "approved")
                              }
                              className="bg-success text-capitalize  text-white me-2 mb-2"
                              disabled={userlogin === details.n_id}
                            >
                              {userlogin === details.n_id ? (
                                <ThreeDots
                                  color="#00BFFF"
                                  height={20}
                                  width={50}
                                />
                              ) : (
                                "Approve"
                              )}
                            </Button>
                            <Button
                              onClick={() =>
                                Activation(details.n_id, "rejected")
                              }
                              className="bg-danger text-capitalize text-white"
                              disabled={userlogin === details.n_id}
                            >
                              Reject
                            </Button>
                          </span>
                        ) : (
                          <Button
                            className={`${
                              details.n_status === "approved"
                                ? "bg-success text-capitalize"
                                : "bg-danger text-capitalize"
                            } text-white`}
                            disabled
                          >
                            {details.n_status}
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Slip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img src={selectedImage} alt="Payment Slip" className="img-fluid" />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IncomeWithdrawwallet;
